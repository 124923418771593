import React from "react"
import { navigate } from "gatsby"

const PrivateRoute = ({
  component: Component,
  authenticated,
  location,
  ...rest
}) => {
  if (!authenticated && location.pathname !== `/venue/login`) {
    navigate("/venue/login")
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
